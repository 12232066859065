import { SVGProps } from 'react'

export const CheckCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.83334 11.5001L7.04167 9.70842C6.88889 9.55564 6.69445 9.47925 6.45834 9.47925C6.22223 9.47925 6.02778 9.55564 5.87501 9.70842C5.72223 9.86119 5.64584 10.0556 5.64584 10.2917C5.64584 10.5279 5.72223 10.7223 5.87501 10.8751L8.25001 13.2501C8.41667 13.4167 8.61112 13.5001 8.83334 13.5001C9.05556 13.5001 9.25001 13.4167 9.41667 13.2501L14.125 8.54175C14.2778 8.38897 14.3542 8.19453 14.3542 7.95842C14.3542 7.7223 14.2778 7.52786 14.125 7.37508C13.9722 7.2223 13.7778 7.14592 13.5417 7.14592C13.3056 7.14592 13.1111 7.2223 12.9583 7.37508L8.83334 11.5001ZM10 18.3334C8.84723 18.3334 7.76389 18.1147 6.75001 17.6772C5.73612 17.2397 4.85417 16.6459 4.10417 15.8959C3.35417 15.1459 2.76042 14.264 2.32292 13.2501C1.88542 12.2362 1.66667 11.1529 1.66667 10.0001C1.66667 8.8473 1.88542 7.76397 2.32292 6.75008C2.76042 5.73619 3.35417 4.85425 4.10417 4.10425C4.85417 3.35425 5.73612 2.7605 6.75001 2.323C7.76389 1.8855 8.84723 1.66675 10 1.66675C11.1528 1.66675 12.2361 1.8855 13.25 2.323C14.2639 2.7605 15.1458 3.35425 15.8958 4.10425C16.6458 4.85425 17.2396 5.73619 17.6771 6.75008C18.1146 7.76397 18.3333 8.8473 18.3333 10.0001C18.3333 11.1529 18.1146 12.2362 17.6771 13.2501C17.2396 14.264 16.6458 15.1459 15.8958 15.8959C15.1458 16.6459 14.2639 17.2397 13.25 17.6772C12.2361 18.1147 11.1528 18.3334 10 18.3334ZM10 16.6667C11.8611 16.6667 13.4375 16.0209 14.7292 14.7292C16.0208 13.4376 16.6667 11.8612 16.6667 10.0001C16.6667 8.13897 16.0208 6.56258 14.7292 5.27092C13.4375 3.97925 11.8611 3.33341 10 3.33341C8.13889 3.33341 6.56251 3.97925 5.27084 5.27092C3.97917 6.56258 3.33334 8.13897 3.33334 10.0001C3.33334 11.8612 3.97917 13.4376 5.27084 14.7292C6.56251 16.0209 8.13889 16.6667 10 16.6667Z"
      fill="#52BB4D"
    />
  </svg>
)
