import { IconProps } from './types'

export const CrossIcon = (props: IconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.99999 8.25548L4.33664 10.9261C4.16803 11.0935 3.95377 11.1772 3.69386 11.1772C3.43394 11.1772 3.21968 11.0935 3.05107 10.9261C2.88368 10.7587 2.79999 10.5457 2.79999 10.287C2.79999 10.0283 2.88368 9.81527 3.05107 9.64787L5.72173 6.97722L3.05107 4.3367C2.88368 4.16809 2.79999 3.95383 2.79999 3.69392C2.79999 3.43401 2.88368 3.21974 3.05107 3.05114C3.21847 2.88374 3.43151 2.80005 3.6902 2.80005C3.9489 2.80005 4.16194 2.88374 4.32934 3.05114L6.99999 5.72179L9.64051 3.05114C9.80912 2.88374 10.0234 2.80005 10.2833 2.80005C10.5432 2.80005 10.7575 2.88374 10.9261 3.05114C11.1087 3.23374 11.2 3.45074 11.2 3.70214C11.2 3.95353 11.1087 4.16261 10.9261 4.3294L8.25542 6.97722L10.9261 9.64057C11.0935 9.80918 11.1772 10.0234 11.1772 10.2834C11.1772 10.5433 11.0935 10.7575 10.9261 10.9261C10.7435 11.1087 10.5268 11.2 10.276 11.2C10.0252 11.2 9.81581 11.1087 9.64781 10.9261L6.99999 8.25548Z"
      fill="white"
      fillOpacity="0.4"
    />
  </svg>
)
