import { ApolloProvider } from '@apollo/client'
import { useApollo } from 'hooks/useApollo'
import { useSetSentryUser } from 'hooks/useSetSentryUser'
import type { AppProps } from 'next/app'
import { Outfit } from 'next/font/google'
import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import { appWithTranslation } from 'next-i18next'
import { ApolloClientProviderProps, NextPageWithLayout } from 'types/next'
import { Toaster } from 'components/ui/toaster'
import { TooltipProvider } from '../components/ui/tooltip'
import '../../globals.css'

/*
 Uncomment for SSG
const getStaticProps = makeStaticProps(['common'])
export { getStaticPaths, getStaticProps }
 */

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
  session?: Session | null
}

const ApolloClientProvider = ({ initialApolloState, children }: ApolloClientProviderProps) => {
  const apolloClient = useApollo(initialApolloState)

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => page)
  useSetSentryUser()

  return getLayout(<Component {...pageProps} />)
}

const AppWithI18n = appWithTranslation(App)

const outfit = Outfit({ subsets: ['latin'] })

const AppWithAuth = (props: AppPropsWithLayout) => {
  const {
    pageProps: { initialApolloState },
    session,
  } = props

  return (
    <main className={outfit.className}>
      <SessionProvider session={session}>
        <ApolloClientProvider initialApolloState={initialApolloState}>
          <TooltipProvider>
            <AppWithI18n {...props} />
          </TooltipProvider>
        </ApolloClientProvider>
        <Toaster />
      </SessionProvider>
    </main>
  )
}

export default AppWithAuth
