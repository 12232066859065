// const RoutesToOmit: string[] = [Route.SetUpProfile, Route.SignIn, Route.SignUp]

export const useSetSentryUser = () => {
  // const { data } = useSession()
  // const [fetchMe, { data: meData }] = useGetUserMeLazyQuery()
  // const { pathname } = useRouter()
  // const isOnCorrectRoute = !RoutesToOmit.includes(pathname)
  // const me = meData?.
  // useEffect(() => {
  //   if (isLoggedIn && isOnCorrectRoute) {
  //     void fetchMe()
  //   }
  // }, [isLoggedIn, fetchMe, isOnCorrectRoute])
  // useEffect(() => {
  //   Sentry.setUser({
  //     id: me?.id,
  //     email: me?.email ?? undefined,
  //   })
  // }, [me])
}
