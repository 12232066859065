import {
  CheckCircleIcon,
  CrossCircleIcon,
  ExclamationCircleIcon,
  InfoCircleIcon,
} from 'components/icons'
import {
  Toast,
  ToastClose,
  ToastProvider,
  ToastTitle,
  ToastVariant,
  ToastViewport,
} from 'components/ui/toast'
import { useToast } from 'components/ui/use-toast'

const TOAST_ICON_CLASSNAME = 'min-h-[20px] min-w-[20px]'

const ToastVariantToIcon: Record<ToastVariant, JSX.Element> = {
  info: <InfoCircleIcon className={TOAST_ICON_CLASSNAME} />,
  success: <CheckCircleIcon className={TOAST_ICON_CLASSNAME} />,
  warning: <ExclamationCircleIcon className={TOAST_ICON_CLASSNAME} />,
  error: <CrossCircleIcon className={TOAST_ICON_CLASSNAME} />,
} as const

export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map(({ id, title, action, variant = 'info', ...props }) => (
        <Toast key={id} {...props}>
          <div className="flex w-full items-center justify-between gap-4">
            <div className="flex items-center gap-4">
              {variant && ToastVariantToIcon[variant]}
              {title && <ToastTitle>{title}</ToastTitle>}
            </div>
            {action}
            <ToastClose />
          </div>
        </Toast>
      ))}
      <ToastViewport />
    </ToastProvider>
  )
}
