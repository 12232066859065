import { SVGProps } from 'react'

export const InfoCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 14.1667C10.2361 14.1667 10.434 14.0869 10.5938 13.9272C10.7535 13.7674 10.8333 13.5695 10.8333 13.3334V10.0001C10.8333 9.76397 10.7535 9.56605 10.5938 9.40633C10.434 9.24661 10.2361 9.16675 10 9.16675C9.7639 9.16675 9.56598 9.24661 9.40626 9.40633C9.24653 9.56605 9.16667 9.76397 9.16667 10.0001V13.3334C9.16667 13.5695 9.24653 13.7674 9.40626 13.9272C9.56598 14.0869 9.7639 14.1667 10 14.1667ZM10 7.50008C10.2361 7.50008 10.434 7.42022 10.5938 7.2605C10.7535 7.10078 10.8333 6.90286 10.8333 6.66675C10.8333 6.43064 10.7535 6.23272 10.5938 6.073C10.434 5.91328 10.2361 5.83342 10 5.83342C9.7639 5.83342 9.56598 5.91328 9.40626 6.073C9.24653 6.23272 9.16667 6.43064 9.16667 6.66675C9.16667 6.90286 9.24653 7.10078 9.40626 7.2605C9.56598 7.42022 9.7639 7.50008 10 7.50008ZM10 18.3334C8.84723 18.3334 7.76389 18.1147 6.75001 17.6772C5.73612 17.2397 4.85417 16.6459 4.10417 15.8959C3.35417 15.1459 2.76042 14.264 2.32292 13.2501C1.88542 12.2362 1.66667 11.1529 1.66667 10.0001C1.66667 8.8473 1.88542 7.76397 2.32292 6.75008C2.76042 5.73619 3.35417 4.85425 4.10417 4.10425C4.85417 3.35425 5.73612 2.7605 6.75001 2.323C7.76389 1.8855 8.84723 1.66675 10 1.66675C11.1528 1.66675 12.2361 1.8855 13.25 2.323C14.2639 2.7605 15.1458 3.35425 15.8958 4.10425C16.6458 4.85425 17.2396 5.73619 17.6771 6.75008C18.1146 7.76397 18.3333 8.8473 18.3333 10.0001C18.3333 11.1529 18.1146 12.2362 17.6771 13.2501C17.2396 14.264 16.6458 15.1459 15.8958 15.8959C15.1458 16.6459 14.2639 17.2397 13.25 17.6772C12.2361 18.1147 11.1528 18.3334 10 18.3334ZM10 16.6667C11.8611 16.6667 13.4375 16.0209 14.7292 14.7292C16.0208 13.4376 16.6667 11.8612 16.6667 10.0001C16.6667 8.13897 16.0208 6.56258 14.7292 5.27092C13.4375 3.97925 11.8611 3.33341 10 3.33341C8.13889 3.33341 6.56251 3.97925 5.27084 5.27092C3.97917 6.56258 3.33334 8.13897 3.33334 10.0001C3.33334 11.8612 3.97917 13.4376 5.27084 14.7292C6.56251 16.0209 8.13889 16.6667 10 16.6667Z"
      fill="white"
    />
  </svg>
)
